<template>
  <div :class="classes">
    <!-- styles for v-html content defined as global styles -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="text"></span>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { useI18nStore } from '@galileo/stores'
export const alignment = ['center', 'left', 'right']
export default {
  name: 'TermsConditions',
  props: {
    alignment: {
      type: String,
      default: alignment[0],
      validator: (value) => alignment.includes(value),
    },
    text: {
      type: String,
      default: () => {
        const i18nStore = useI18nStore()
        return i18nStore.$t('PageTermsConditions.Description.HTML', {
          locale: i18nStore.i18n.locale,
        }).value
      },
    },
    isSubtitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return [
        `text-${props.alignment}`,
        { 'terms-caption': !props.isSubtitle, 'terms-subtitle': props.isSubtitle },
      ]
    })

    return {
      classes,
    }
  },
}
</script>

<style scoped>
.terms-caption {
  @apply type-caption;
}
.terms-subtitle {
  @apply type-subtitle;
}
</style>
